export default function initShowPasswordCheckbox() {
	const triggerElem = document.getElementById('show-password')

	if (triggerElem) {
		triggerElem.addEventListener('change', function (event) {
			const targetElem = document.querySelector('.js-show-password__target')

			if (event.currentTarget.checked) {
				targetElem.setAttribute('type', 'text')
			} else {
				targetElem.setAttribute('type', 'password')
			}
		})
	}
}
