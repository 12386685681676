import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

// This is tightly coupled with the back-end. The back-end sets a flash message using the helper
// method `flash_message` in `app/helpers/application_helper.rb`. We then call render_flash and that
// adds these messages to window.flashMessages. We then iterate over those messages and display them.

// window.sessionStorage.flashMessage is used in one place right now. After a caregiver is added we
// redirect to the compliance report (main dashboard) and display a message.

function messageFromSessionStorage() {
	const message = attemptSessionAccess()

	if (message) {
		window.sessionStorage.removeItem('flashMessage')
		window.flashMessages.push({ message, type: 'notice' })
	}
}

function attemptSessionAccess() {
	let message = null

	try {
		message = window.sessionStorage.flashMessage
	} catch (e) {
		if (e.name === 'SecurityError') {
			// Cookies are likely disabled. Ignore this error.
		} else {
			// Re-throw the error if it's not a SecurityError
			throw e
		}
	}

	return message
}

export default function initFlashMessages() {
	window.flashMessages = window.flashMessages || []

	messageFromSessionStorage()
	flashMessages.forEach(function (message) {
		if (message.type === 'notice') {
			GlobalStore.onHandleSuccessMessages({ message: message.message })
		} else {
			GlobalStore.onHandleErrorMessages({ error: message.message })
		}
	})
}
